import { Injectable } from '@angular/core';
import { Plan, PricingPlanGroup } from 'src/app/types/plan';
import { PlanTypeEnum } from '../../enums/planType.enum';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Result } from '../../types/result';
import { ThemeConfigService } from '../theme-config/theme-config.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  private plans: Plan[];

  public plansText: { id: string, text: string }[] = [];


  constructor(private httpClient: HttpClient,private themeConfigService:ThemeConfigService) {

  }

  private setPlansText(plans) {
    this.plansText = plans.map(plan => {
      return this.getPlanText(plan);
    });
  }

  public getPlanText(plan) {

    let planText;
    if (plan.planType === PlanTypeEnum.weekly) {
      planText = {
        id: plan.id,
        text: `${plan.minLikes} - ${plan.maxLikes} Likes for ${plan.price}$ per Week`
      };
    } else {
      planText = {
        id: plan.id,
        text: `${plan.minLikes} - ${plan.maxLikes} Likes for ${plan.price}$ per Month`
      };
    }

    return planText;
  }

  async getPricingPlanById(id: string) {
    if (!this.plans) {
      return this.httpClient.get<Result<Plan>>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getPlan?id=${id}`).toPromise().then(result => {
        return result.value;
      });
    } else {
      return this.plans.find(plan => plan.id === id);
    }
  }

  getPricingPlanTextById(plans, id: string) {
    if (!this.plansText?.length) {
      this.setPlansText(plans);
    }
    return this.plansText.find(plan => plan.id === id);
  }

  async getPricingPlans(): Promise<Result<Plan[]>> {
    return this.httpClient.get<Result<Plan[]>>(`${this.themeConfigService.themeConfig.backendUrl}/plan/getPlans`).toPromise().then(results => {
      this.plans = results.value;
      return results;
    });
  }

  async getPricingPlanGroups(): Promise<PricingPlanGroup[]> {
    const pricingPlanGroups: PricingPlanGroup[] = [];
    const plansResponse = await this.getPricingPlans();
    this.plans = plansResponse.value;
    this.setPlansText(this.plans);
    for (let parentIndex = 1; parentIndex <= this.plans.length / 2; parentIndex++) {
      const pricingPlanGroup: PricingPlanGroup = {
        monthlyPlan: {
          bestSeller: false,
          bestValue: false,
          id: '0',
          maxLikes: 0,
          minLikes: 0,
          parentId: '0',
          planType: PlanTypeEnum.monthly,
          price: 120,
          features: ['1', '2', '3', '4', '5']
        },
        weeklyPlan: {
          bestSeller: false,
          bestValue: false,
          id: '0',
          maxLikes: 0,
          minLikes: 0,
          parentId: '0',
          planType: PlanTypeEnum.monthly,
          price: 120,
          features: ['1', '2', '3', '4', '5']
        }
      };

      this.plans.forEach((plan) => {
        if (Number(plan.parentId) === parentIndex) {
          if (plan.planType === PlanTypeEnum.weekly) {
            pricingPlanGroup.weeklyPlan = plan;
          } else {
            pricingPlanGroup.monthlyPlan = plan;
          }
        }
      });

      pricingPlanGroups.push(pricingPlanGroup);
    }

    return pricingPlanGroups;
  }

  getWeeklyPricingPlansText(): { id: string, text: string }[] {
    const res: { id: string, text: string }[] = [];
    this.plans.forEach((plan) => {
      if (plan.planType === PlanTypeEnum.weekly) {
        res.push(this.getPricingPlanTextById(this.plans, plan.id));
      }
    });

    return res;
  }

  getMonthlyPricingPlansText(): { id: string, text: string }[] {
    const res: { id: string, text: string }[] = [];
    this.plans.forEach((plan) => {
      if (plan.planType === PlanTypeEnum.monthly) {
        res.push(this.getPricingPlanTextById(this.plans, plan.id));
      }
    });

    return res;
  }
}
